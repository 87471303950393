body {
    background-color: #022C43;
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
}

.app {
    z-index: 1;
}

.home {
    justify-content: center;
    text-align: center;
    margin-top: 9em;
}

.title {
    font-size: 6em;
    margin: 0;
    color: white;
}

.subtitle {
    font-size: 2.8em;
    color: #ff9800;
    margin: 0;
}

.social-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-links a {
    display: flex;
    align-items: center;
    margin: 0 10px;
    color: #808080;
    text-decoration: none;
    font-size: 40px;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #ffffff;
}

.social-links a svg {
    margin-right: 5px;
}

.background-pattern {
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    z-index: -1;
    pointer-events: none;
    width: 100%;
    background: linear-gradient(45deg, #011e2d 20%, transparent 20%),
                linear-gradient(-45deg, #014469 20%, transparent 20%),
                linear-gradient(45deg, transparent 75%, #05324b 75%),
                linear-gradient(-45deg, transparent 90%, #003f64 90%);
}
