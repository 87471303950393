.bottom-bar {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
}

.bottom-bar p {
    margin: 0;
}
