.projects {
    text-align: center;
    color: #fff;
    padding: 50px 0;
    margin-top: 4em;
}

.projects h2 {
    font-size: 3em;
    margin-bottom: 35px;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.project-card-wrapper {
    outline: 1px solid #565656;
    border-radius: 10px;
    margin-bottom: 30px;
}

.project-card {
    width: 250px;
    margin: 10px 10px 20px;
    text-align: center;
}

.project-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
}

.project-info {
    text-align: center;
}

.project-info h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.project-info p {
    font-size: 1.2em;
    line-height: 1.6;
}

.project-info a {
    color: #fff;
    text-decoration: none;
    font-size: 1.1em;
    transition: color 0.3s ease;
    text-align: center;
}

.project-info a:hover {
    color: #ffcc00;
}

.project-card img {
    margin-top: 10px;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.project-card img:hover {
    transform: scale(1.1);
}

.project-info {
    text-align: center;
}

.project-info h3 {
    font-size: 2em;
    margin-bottom: 10px;
}

.project-info p {
    font-size: 1.2em;
    line-height: 1.6;
}

.technology-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.technology-cards span {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
}

.technology-cards span:hover {
    transform: scale(1.1);
    background-color: #555;
    opacity: 1;
}

