.about-me {
    text-align: center;
    color: #fff;
    padding-top: 12em;
}

.about-me h2 {
    font-size: 3.5em;
    margin-bottom: 20px;
}

.about-me-text-container {
    margin-bottom: 30px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.about-me-text-container a {
    color: #fff;
    text-decoration: none;
    font-size: 1.1em;
    transition: color 0.3s ease;
}

.about-me-text-container a:hover {
    color: #ff9800;
}

.about-me-text-container p {
    font-size: 1.2em;
    margin: 0;
    line-height: 1.6;
}

.skills {
    margin-top: 2em;
}

.skills h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.skills ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.skills li {
    display: inline-block;
    margin-right: 10px;
    font-size: 1.2em;
    background-color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.8;
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
}

.skills li:hover {
    transform: scale(1.1);
    background-color: #555;
    opacity: 1;
}
