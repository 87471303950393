.contact {
    text-align: center;
    color: #fff;
    margin-top: 4em;
}

.contact h2 {
    font-size: 3.5em;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}

.contact-form button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #555;
}

.map {
    position: relative;
    padding-bottom: 6em;
}

.map-title {
    font-size: 2em;
    margin-bottom: 10px;
}

.map iframe {
    min-width: 20em;
    max-width: 40%;
    height: 20em;
    margin: 0 auto;
    display: block;
    border: none;
    border-radius: 10px;
}
